<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <header-image :title="headerTitle"></header-image>
    <!-- /头部 -->

    <!-- 体部 -->
    <ralt-info></ralt-info>
    <!-- /体部 -->

    <!-- 尾部 -->
    <Footer></Footer>
    <!-- /尾部 -->

  </div>
</template>
 
<script>
import { get, post } from "@/utils/request";
import Carousel from "../components/Carousel";
  export default {
    name: "index",
    components: {},
    data() {
      return {
        headerTitle:"资费中心",

    };
  },
  created() {},
  methods: {},

};
</script>

<style lang='scss'>
  // 体部样式


</style>